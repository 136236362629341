//import React from 'react';

// const IconCodepen = () => (
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     role="img"
//     viewBox="0 0 24 24"
//     fill="none"
//     stroke="currentColor"
//     strokeWidth="2"
//     strokeLinecap="round"
//     strokeLinejoin="round"
//     className="feather feather-codepen">
//     <polygon points="12 2 22 8.5 22 15.5 12 22 2 15.5 2 8.5 12 2"></polygon>
//     <line x1="12" y1="22" x2="12" y2="15.5"></line>
//     <polyline points="22 8.5 12 15.5 2 8.5"></polyline>
//     <polyline points="2 15.5 12 8.5 22 15.5"></polyline>
//     <line x1="12" y1="2" x2="12" y2="8.5"></line>
//   </svg>
// );

// export default IconCodepen;

// import React, { forwardRef } from 'react';
// import PropTypes from 'prop-types';


// const IconCodepen = forwardRef(({ color = 'currentColor', size = 24, ...rest }, ref) => {
//   return (
//     <svg
//       ref={ref}
//       xmlns="http://www.w3.org/2000/svg"
//       width={size}
//       height={size}
//       viewBox="0 0 24 24"
//       fill="none"
//       stroke={color}
//       strokeWidth="2"
//       strokeLinecap="round"
//       strokeLinejoin="round"
//       {...rest}
//     >
//       <line x1="18" y1="6" x2="6" y2="18" />
//       <line x1="6" y1="6" x2="18" y2="18" />
//     </svg>
//   );
// });

// IconCodepen.propTypes = {
//   color: PropTypes.string,
//   size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// };

// IconCodepen.displayName = 'X';

// export default IconCodepen;

import React from 'react';

const IconCodepen= () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -62 512.002 512">

    <path d="M334.809 170.992l-113.114-61.89c-6.504-3.559-14.191-3.426-20.566.351-6.379 3.781-10.184 10.461-10.184 17.875v122.719c0 7.379 3.782 14.047 10.118 17.832 3.308 1.976 6.976 2.969 10.652 2.969a20.74 20.74 0 009.848-2.504L334.68 207.52a20.772 20.772 0 0010.937-18.223 20.763 20.763 0 00-10.808-18.305zm-113.86 63.617v-91.718l84.54 46.257zm0 0"></path>
    <path d="M508.234 91.527l-.023-.234c-.434-4.121-4.75-40.777-22.57-59.422C465.043 9.941 441.69 7.277 430.46 6a135.45 135.45 0 01-2.543-.305l-.895-.093C359.336.68 257.113.008 256.09.004L256 0l-.09.004C254.887.008 152.664.68 84.367 5.602l-.902.093c-.727.098-1.528.188-2.399.29-11.101 1.28-34.203 3.949-54.859 26.671C9.234 51.102 4.328 86.973 3.824 91.004l-.058.523C3.613 93.242 0 134.067 0 175.051v38.312c0 40.985 3.613 81.809 3.766 83.528l.027.257c.434 4.055 4.746 40.04 22.484 58.692 19.368 21.195 43.856 24 57.028 25.508 2.082.238 3.875.441 5.097.656l1.184.164c39.082 3.719 161.617 5.55 166.812 5.625l.157.004.156-.004c1.023-.004 103.242-.676 170.93-5.598l.894-.093c.856-.114 1.817-.215 2.871-.325 11.04-1.172 34.016-3.605 54.387-26.02 16.973-18.448 21.883-54.32 22.383-58.347l.058-.523c.153-1.719 3.77-42.54 3.77-83.524v-38.312c-.004-40.985-3.617-81.805-3.77-83.524zm-26.238 121.836c0 37.934-3.312 77-3.625 80.586-1.273 9.88-6.45 32.574-14.719 41.563-12.75 14.027-25.847 15.418-35.41 16.43a164.16 164.16 0 00-3.195.359c-65.469 4.734-163.832 5.46-168.363 5.488-5.082-.074-125.825-1.922-163.715-5.441-1.942-.317-4.04-.559-6.25-.809-11.215-1.285-26.567-3.043-38.371-16.027l-.278-.297c-8.125-8.465-13.152-29.688-14.43-41.149-.238-2.71-3.636-42.238-3.636-80.703v-38.312c0-37.89 3.305-76.914 3.625-80.574 1.52-11.637 6.793-32.957 14.719-41.575 13.14-14.453 26.996-16.054 36.16-17.113.875-.102 1.691-.195 2.445-.293 66.422-4.758 165.492-5.465 169.047-5.492 3.555.023 102.59.734 168.422 5.492.808.102 1.691.203 2.64.313 9.426 1.074 23.672 2.699 36.747 16.644l.12.129c8.126 8.465 13.153 30.059 14.43 41.75.227 2.559 3.637 42.172 3.637 80.719zm0 0"></path>
  </svg>
);

export default IconCodepen;